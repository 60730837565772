@import 'styles/main';

.root {
  margin: 0;
}

.size-extra-small {
  font-size: 10px;
  line-height: 12px;
}

.size-small {
  font-size: 14px;
  line-height: 18px;
}

.size-small-medium {
  font-size: 16px;
  line-height: 20px;
}

.size-medium {
  font-size: 20px;
  line-height: 24px;
}

.size-large {
  font-size: 24px;
  line-height: 32px;
}

.size-extra-large {
  font-size: 32px;
  line-height: 36px;
}

.weight-regular {
  font-weight: 400;
}

.weight-medium {
  font-weight: 500;
}

.weight-bold {
  font-weight: 700;
}

.muted {
  color: $color-muted;
}
