@import 'styles/main.scss';

.row {
  display: flex;
}

.cell {
  width: 100%;
  margin-left: 65px;
}

.aside {
  flex-shrink: 0;
  width: 384px;
  top: 80px;
  position: sticky;
  max-height: 100vh;
  overflow-y: auto;
}

.subtitle {
  margin: 0;
  margin-bottom: $gap16;
}

.description {
  margin: 0;
  margin-bottom: $gap48;
}

.accordion {
  background-color: #F9FAFB;
  padding: 0 $gap24;
  border-radius: 16px;
}

.addButton {
  margin-bottom: $gap24;
}

.pointButton {
  margin-bottom: $gap40;
}

.textarea {
  margin-bottom: $gap40;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba($color-gray, 0.12);
  padding: $gap24;
  border-radius: 16px;
}

.footerText {
  font-size: 18px;
  margin-right: $gap8;
  display: flex;
  align-items: center;
}

.modal {
  background-color: $color-white;
  border-radius: 16px;
  padding: $gap40;
}

.modalQuote {
  background: rgba(151, 175, 185, 0.06);
  padding: $gap16;
  border-radius: 16px;
  margin-bottom: $gap8;
}

.img {
  margin-right: $gap8;
}
.prompt {
  margin-bottom: $gap24;
}