@import 'styles/main.scss';

.title {
  text-align: center;
  margin: 0;
  margin-bottom: $gap32;
}

.description {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  margin-bottom: 74px;
}

.buttons {
  margin: 24px 0;
}

.buttons button {
  margin-bottom: 12px;
}

.select {
  display: flex;
  & .button {
    margin-left: $gap16;
    flex-shrink: 0;
  }
  & .selectItem {
    width: 100%;
  }
}

.img {
  margin-left: $gap8;
}
