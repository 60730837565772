@import 'styles/main.scss';

.row {
  display: flex;
}

.cell {
  width: 100%;
  margin-left: 65px;
}

.aside {
  flex-shrink: 0;
  width: 384px;
  position: sticky;
  top: 0;
  max-height: calc(100vh);
  overflow-y: auto;
}

.subtitle {
  margin: 0;
  margin-bottom: $gap16;
}

.subtitleAside {
  margin-bottom: $gap24;
}

.description {
  margin: 0;
  margin-bottom: $gap48;
}

.points {
  padding: $gap24;
  background: rgba(151, 175, 185, 0.06);
  border-radius: 16px;
}

.footer {
  background: rgba(151, 175, 185, 0.12);
  border-radius: 16px;
  padding: $gap24;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    margin-left: $gap24;
  }
}

.footerText {
  display: flex;
  align-items: center;
}

.textArea {
  margin-bottom: $gap40;
}

.img {
  margin-right: $gap16;
}

.pointMainColum {
  display: flex;
  flex-direction: row;
}

.mainColumArrow {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-top: 10px;
}

.columArrow {
  cursor: pointer;
  border: 1px solid #e6ecee;
}

.columArrow:hover {
  background-color: rgba(151, 175, 185, 0.24);
}
