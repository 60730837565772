@import 'styles/main.scss';

.root {
  position: relative;
}

.elem {
  padding: $gap24;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  margin-bottom: $gap32;
  width: 100%;
  pointer-events: none;

  &.active {
    background: rgba(151, 175, 185, 0.06);
  }
}

.title {
  margin-bottom: $gap4;
}

.textarea {
  margin-bottom: $gap16;
  border: 2px dashed rgba(151, 175, 185, 0.24);
  text-align: center;
  min-height: 95px;

  &:focus {
    text-align: left;
    & + .elem {
      opacity: 0;
    }
  }

  &.active {
    border: 2px solid rgba(151, 175, 185, 0.24);
    text-align: left;
  }

  &.border {
    border: 2px dashed rgba(151, 175, 185, 0.24);
    background: rgba(151, 175, 185, 0.06);
  }
}
