@import 'styles/main.scss';

.root {
  margin-bottom: 50px;
}

.item {
  background: rgba(151, 175, 185, 0.16);
  border-radius: 16px;
  padding: $gap32 $gap24;
  margin-bottom: $gap8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;

  &:disabled {
    cursor: default;
    color: $color-black;
  }
}

.title {
  margin-bottom: $gap24;
}

.wrapper {
  margin-bottom: $gap32;
}


.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status {
  font-weight: 500;
  font-size: 20px;
  color: $color-green;
  margin-left: auto;
  flex-shrink: 0;
  margin-left: 24px;
}

.statusProggress {
  font-weight: 500;
  font-size: 20px;
  color: $color-gray;
  margin-left: auto;
  flex-shrink: 0;
  margin-left: 24px;
}

.pureItem {
  padding: $gap32 $gap24;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(151, 175, 185, 0.12);
  border-radius: 16px;
}

.pureWrap {
  display: flex;
  align-items: center;

  img {
    margin-right: $gap16;
  }
}

.button {
  font-weight: 500;
}

.modal {
  background-color: $color-white;
  max-width: 585px;
  width: 100%;
  padding: $gap40;
  border-radius: 16px;
}

.modalTitle {
  margin-bottom: $gap16;
}

.modalText {
  margin-bottom: $gap24;
}

.listcol {
  display: flex;
  flex-direction: row;
  position: relative;
}

.removeItem {
  position: absolute;
  cursor: pointer;
  top: -8px;
  right: -15px;
  border: none;
  background: none;
}

.removeItem > img {
  width: 20px;
}
