@import 'styles/main.scss';

.title {
  text-align: center;
  margin: 0;
  margin-bottom: $gap32;
}

.description {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  margin-bottom: 74px;
}

.buttons {
  margin: 24px 0;
}

.buttons button {
  margin-bottom: 12px;
}

.select {
  display: flex;
  & .button {
    margin-left: $gap16;
    flex-shrink: 0;
  }
  & .button_hidden {
    margin-left: $gap16;
    visibility: hidden;
  }
  & .input {
    width: 100%;
  }
  & .top {
    margin-top: $gap16;
  }
}

.img {
  display: inline-block;
  margin-left: $gap8;
}

.error {
  padding: $gap4 $gap12;
  color: #af0808;
}

.pincode {
  margin-top: $gap16;
}
