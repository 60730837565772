@import 'styles/main';

$select-padding-horizontal: $gap16;

.root {
  position: relative;
}

.list {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
  border-radius: 5px;
  overflow: hidden;
  max-height: 242px;
  overflow-y: auto;

  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  margin-top: $gap24;

  &.active {
    box-shadow: 2px 4px 16px rgba(108, 131, 153, 0.12);
    border-radius: 16px;
    z-index: 1;
  }
}

.icon {
  position: absolute;
  right: 16px;
  top: 0;
  height: 20px;
  bottom: 0;
  margin: auto;
  color: $color-black;
  pointer-events: none;

  &.active {
    transform: rotate(180deg);
    transform-origin: center;
  }
}

.value {
  width: 100%;
  border: 4px solid $color-black;
  border-radius: 16px;
  padding: 0 $gap32;
  height: 88px;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  transition: border-color $transitionTime;
  padding-right: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: $color-white;

  &:not(.disabled) {
    &:hover {
      border-color: #c4c4c4;
    }

    &.error {
      border-color: red;
      color: red;
    }
  }

  &.active {
    border-color: #c4c4c4;
  }
}


.option {
  padding: $gap12 $gap32;
  color: $color-black;
  background-color: $color-white;
  cursor: pointer;

  font-size: 24px;
  line-height: 28px;

  transition: color 0.15;

  &:first-child {
    padding-top: $gap28;
  }

  &:last-child {
    padding-bottom: $gap28;
  }

  &.active {
    color: $color-blue;
  }

  &:hover {
    color: $color-blue;
  }
}
