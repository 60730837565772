@import 'styles/main';

.root {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  overflow: hidden;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    .wrapper {
      transform: rotateY(360deg);
    }
  }
}

.front, .back {
  position: relative;
  width: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  overflow: hidden;
}

.front {
  position: relative;
}


.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;

  background-color: $color-white;
  padding: $gap16;
  border-radius: 16px;
  margin-bottom: $gap16;
}

.header {
  border-bottom: 1px solid $color-border;
  padding-bottom: $gap12;
  margin-bottom: $gap16;
}

.subTitle {
  text-transform: uppercase;
  font-weight: 600;
  color: $color-red;
}

.title {
  margin-bottom: $gap8;
}

.content {
  margin-bottom: $gap12;
}

.footer {
  display: flex;
  align-items: center;
  margin-bottom: $gap8;
}

.footerText {
  margin-right: $gap8;
  font-weight: 500;
  color: $color-gray;
  min-height: 22px;
}

.footerTextBlue {
  color: $color-blue;
  font-weight: 500;
  min-height: 32px;
}

.contentText {
  white-space: pre-line;
}
.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $gap12;

  button {
    &:hover {
      background-color: rgba(151, 175, 185, 0.2);
    }
  }
}