@import 'styles/main';

.root {
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0;

  &:disabled {
    cursor: not-allowed;
  }

  &.loading > *:not(.loader) {
    opacity: 0;
  }

  transition: background-color $transitionTime, color $transitionTime;
}

// themes
.theme-primary {
  color: $color-white;
  background-color: $color-green;

  // @include hover {
  //   &:not(:disabled),
  //   &:not(:disabled) {
  //     // background-color: $color-sea-green;
  //   }
  // }
  &:disabled {
    opacity: 0.48;
  }
}

.theme-secondary {
  background-color: $color-black;
  color: $color-white;
  // @include hover {
  //   &:not(:disabled),
  //   &:not(:disabled) {
  //     opacity: 0.75;
  //   }
  // }

  &:disabled {
    opacity: 0.48;
  }
}

.theme-gray-blue {
  background-color: rgba($color-gray, 0.12);
  color: $color-blue;
  font-weight: 600;

  // @include hover {
  //   &:not(:disabled),
  //   &:not(:disabled) {
  //     opacity: 0.75;
  //   }
  // }
  &:disabled {
    opacity: 0.48;
  }

  &.size-small {
    padding: 0 $gap16;
  }
}

.theme-gray {
  background-color: rgba($color-gray, 0.12);
  color: $color-gray;

  // @include hover {
  //   &:not(:disabled),
  //   &:not(:disabled) {
  //     opacity: 0.75;
  //   }
  // }
  &:disabled {
    opacity: 0.48;
  }

  &.size-small {
    padding: 0 $gap16;
  }
}

.theme-blue {
  background-color: $color-blue;
  color: $color-white;
}

.theme-white {
  background-color: transparent;
  border: 2px solid $color-white;
  color: $color-white;
}

//sizes
.size-small {
  font-size: 12px;
  font-weight: 600;
  min-height: 32px;
  border-radius: 8px;
  padding: 0 16px;
}

.size-medium {
  font-size: 16px;
  font-weight: 500;
  min-height: 48px;
  border-radius: 12px;
  padding: 0 24px;
}

.size-large {
  font-size: 18px;
  font-weight: 500;
  min-height: 56px;
  border-radius: 12px;
  padding: 0 32px;
}

.size-extra-large {
  font-size: 24px;
  font-weight: 600;
  min-height: 88px;
  border-radius: 16px;
  padding: 0 40px;
}

.fullWidth {
  width: 100%;
  text-align: center;
}

.loader {
  display: none;

  &.active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      animation-name: spin;
      animation-duration: 800ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

.rounded {
  border-radius: 56px;
}

.ghost {
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  cursor: pointer;
  min-height: 0;
}

.uppercase {
  text-transform: uppercase;
}
