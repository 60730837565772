@import 'styles/main.scss';

.root {
  padding-bottom: 76px;
}

.title {
  margin: 0;
  margin-bottom: $gap40;
}

.buttons {
  display: flex;
  align-items: center;
  margin-bottom: 80px;

  & button {
    margin-right: $gap12;

    &:last-child {
      margin-right: 0;
    }
  }
}