@import 'styles/main';

$input-padding-horizontal: $gap16;

.root {
  border: 4px solid $color-black;
  border-radius: 16px;
  padding: 0 $gap32;
  height: 88px;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  transition: border-color $transitionTime;
  background-color: $color-white;
  display: block;
  width: 100%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px white inset;
    box-shadow: 0 0 0 60px white inset;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    // color: $color-placeholder;
  }

  &:disabled {
    color: $color-gray;
    cursor: not-allowed;
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled),
  &:active:not(:disabled) {
    // border-color: $color-input-border-focus;
  }

  &.error {
    border-color: $color-black;
    color: $color-black;

    & + .label {
      color: $color-black;
    }

    &:focus:not(:disabled),
    &:hover:not(:disabled),
    &:active:not(:disabled) {
      border-color: $color-black;
    }
  }
}

