.tooltip_def {
  padding-bottom: 20px;
}

.tooltip {
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  //width: 120px;
  background-color: #f1f8ff;
  color: #1F70B8;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 9999;
  top: 75%;
  left: 17%;
  margin-left: -60px;
  width: inherit;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #1f70b8 transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
