@import 'styles/main.scss';

.root {
  border-bottom: 1px solid $color-border;

  &:last-child {
    border-bottom: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 0;
  min-height: 0;
  padding: $gap24 0 10px;

  & svg {
    flex-shrink: 0;
  }
}

.title {
  margin: 0;
  margin-right: $gap16;
  width: 100%;
  text-align: left;
}
