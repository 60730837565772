@import 'styles/main';

.root {
  background-color: $color-white;
  padding: $gap16;
  border-radius: 16px;
  margin-bottom: $gap16;

  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.name {
  margin-bottom: $gap8;
}


.text {
  white-space: pre-line;
}