@import 'styles/main';

.root {
  background-color: $color-black;
  padding: $gap12 72px;
  position: sticky;
  top: 0;
  z-index: 22;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  font-weight: 600;
  font-size: 16px;
  color: $color-white;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & button {
    margin-left: $gap32;
  }
}

.text {
  font-weight: 500;
  font-size: 16px;
  color: $color-white;
}

.save {
  display: flex;
  align-items: center;
  margin-left: 24px;

  & img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
}

.credit_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: 8px 10px;
  & p {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    margin: 0;
  }
  & span {
    color: #FFFFFF;
    margin-right: 3px;
  }
  & button {
    background: #008CFC;
    border-radius: 8px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 8px 15px;
    cursor: pointer;
  }
}
