@import 'styles/main';

.modalCredit {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 30px 40px;

  & button {
    align-items: center;
    padding: 16px 32px;
    background: #66D775;
    border-radius: 12px;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.buyTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: #0B0D0C;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buyClose {
  height: 24px;
  cursor: pointer;
  margin-left: 15px;
}

.buyDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #5B737D;
  margin: 16px 0px;
}

.buyInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  & input {
    background: #E9E9E9;
    border-radius: 12px;
    outline: none;
    border: none;
    height: 49px;
    width: 280px;
    padding-left: 10px;
    box-sizing: border-box;
    margin-right: 13px;
    color: #5B737D;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}
  & span {
    background: rgba(151, 175, 185, 0.16);
    border-radius: 12px;
    height: 49px;
    padding: 0 29px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #5B737D;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

.error {
  padding: $gap4 $gap12;
  color: #af0808;
  margin-top: 10px;
  text-align: center;
}

.orLineCol {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.orLine {
  background-color: #000000;
  border-top: 1px solid;
  display: flex;
  flex: 1;
}

.orLineTx {
  margin: 15px 10px;
}
