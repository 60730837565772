@import 'styles/main.scss';

.root {
  margin-bottom: $gap48;
}

.title {
  margin-bottom: $gap48;
}

.subTitle {
  margin-bottom: $gap16;
}

.content {
  margin-bottom: $gap40;
  white-space: pre-line;
}

.footer {
  display: flex;
  align-items: center;

  & button {
    margin-right: $gap16;;
  }
}

.modal {
  background-color: $color-white;
  max-width: 463px;
  width: 100%;
  padding: $gap40;
  border-radius: 16px;
}

.modalTitle {
  margin-bottom: $gap16;
}

.modalFooter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $gap16;
  margin-top: $gap24;
}