//colors
$color-black: #0b0d0c;

$color-white: #ffffff;

$color-gray: #97afb9;

$color-muted: #5b737d;

$color-green: #66d775;

$color-blue: #008cfc;

$color-red: #FF003A;


$color-border: #D8E2E7;

$transitionTime: 0.2s;
$transition: 0.2s ease;

$tablet: 767px;
$mobile: 480px;
$laptop: 1024px;

$container-width: 1080px;
$container-medium-width: 720px;
$container-small-width: 592px;

//gaps

$gap2: 2px;
$gap4: 4px;
$gap8: 8px;
$gap12: 12px;
$gap16: 16px;
$gap20: 20px;
$gap24: 24px;
$gap26: 26px;
$gap28: 28px;
$gap32: 32px;
$gap36: 36px;
$gap40: 40px;
$gap44: 44px;
$gap48: 48px;
$gap56: 56px;

$mobile-container-gap: $gap12;
