@import 'styles/main.scss';

.title {
  margin-bottom: $gap16;
}

.wrapper {
  margin-bottom: $gap48;
}

.textArea {
  margin-bottom: $gap40;
}

.modal {
  background-color: $color-white;
  border-radius: 16px;
  padding: $gap40;
  max-width: 853px;
  width: 100%;
}

.modalText {
  margin-bottom: $gap24;
}

.modalTitle {
  margin-bottom: $gap24;
}

.modalText {
  white-space: pre-line;
}