@import 'styles/main.scss';

.root {
  border: 2px solid $color-border;
  border-radius: 16px;
  padding: $gap24;
  transition: border-color $transitionTime;
  resize: none;
  width: 100%;
  min-width: 100%;
  font-family: 'Inter';
  font-size: 16px;
  background-color: $color-white;
  display: block;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px white inset;
    box-shadow: 0 0 0 60px white inset;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: $color-muted;
  }

  &:disabled {

  }

  // &:focus, &:hover, &:active {
  //   border-color: $color-muted;
  // }
}

