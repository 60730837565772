@import 'styles/main';

.closeButton {
  position: absolute;
  color: $color-black;
  right: 40px;
  top: 40px;

  width: 48px;
  height: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}