.root {
  letter-spacing: 0.01em;
  margin: 0;
}

.level-1 {
  font-size: 64px;
  line-height: 68px;
}

.level-2 {
  font-size: 40px;
  line-height: 48px;
}

.level-3 {
  font-size: 32px;
  line-height: 36px;
}

.level-4 {
  font-size: 24px;
  line-height: 28px;
}

.level-5 {
  font-size: 20px;
  line-height: 24px;
}

.level-6 {
  font-size: 16px;
  line-height: 22px;
}

.weight-medium {
  font-weight: 500;
}

.weight-bold {
  font-weight: 700;
}
.weight-extra-bold {
  font-weight: 900;
}
