@import 'styles/main.scss';

.root {
  background: rgba(0, 140, 252, 0.06);
  border-radius: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #0054BA;
  padding: $gap16 $gap24;

  & span {
    color: #FF002E;
    font-weight: bold;
  }

  & strong {
    color: #008BFF;
  }
}