@import 'normalize.css';
@import './variables';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap');

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-black;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.container-small {
  max-width: 996px;
  margin: 0 auto;
}

.main-wrapper {
  margin-top: 80px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  min-height: 100%;
  padding: 24px;
  overflow-y: auto;
}

// .popup-content {
//   max-width: 464px;
// }
