@import 'styles/main';

.root {
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 48px;
  }
}

.title {
  margin: 0;
  margin-bottom: $gap24;
}

.description {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  background: rgba(0, 140, 252, 0.06);
  border-radius: 16px;
  padding: 16px 24px;
  color: #0054BA;

  & span {
    color: #FF002E;
    font-weight: bold;
  }
}

.button {
  margin-top: $gap40;
}

.small_desc{
  font-size: 14px;
  letter-spacing: 0;
  line-height: initial;
}
